import { ResultsContextInterface } from '.';
import actions from './actions';

interface ActionProps {
  type: string;
  value: any;
}

const reducers = (state: ResultsContextInterface, action: ActionProps) => {
  switch (action.type) {
    case actions.SET_LOADING: {
      return {
        ...state,
        loading: action.value
      };
    }
    case actions.SET_SELECTED_DOCUMENT: {
      return {
        ...state,
        selectedDocument: action.value
      };
    }
    case actions.SET_DOCUMENT_RESULTS: {
      return {
        ...state,
        documentResults: action.value
      };
    }
    case actions.SET_APPLICATION_RESULTS: {
      return {
        ...state,
        applicationResults: action.value || []
      };
    }
    case actions.SET_MODULE_TAB_RESULTS: {
      return {
        ...state,
        moduleTabResults: action.value || []
      };
    }
    case actions.SET_VIEW_TYPE: {
      return {
        ...state,
        viewType: action.value
      };
    }
    case actions.SET_TOGGLE_VIEW_TYPE: {
      return {
        ...state,
        toggleViewType: action.value
      };
    }
    case actions.SET_DECRYPTED_SOURCE: {
      return {
        ...state,
        decryptedSource: action.value
      };
    }
    case actions.SET_LABEL_COMPARISON_SELECTION: {
      return {
        ...state,
        labelComparisonSelection: action.value,
        comparisonApplications: []
      };
    }
    case actions.SET_IS_FAVORITE: {
      return {
        ...state,
        isFavorite: action.value
      };
    }
    case actions.SET_IS_SUBSCRIBED: {
      return {
        ...state,
        isSubscribed: action.value
      };
    }
    case actions.SET_ENTITY_LIST: {
      return {
        ...state,
        entities: action.value
      };
    }
    case actions.SET_IN_PROJECTS: {
      return {
        ...state,
        inProjects: action.value
      };
    }
    case actions.SET_DECRYPTED_PAYLOAD: {
      return {
        ...state,
        decryptedPayload: action.value
      };
    }
    case actions.SET_HIDDEN_APPLICATIONS: {
      return {
        ...state,
        hiddenApplications: action.value
      };
    }
    case actions.SET_APPLICATIONS: {
      return {
        ...state,
        applications: action.value
      };
    }
    case actions.SET_IS_GROUP_BY_FILTER_APPLIED: {
      return {
        ...state,
        isGroupByFilterApplied: action.value
      };
    }
    case actions.TRIGGER_RESTORE_APPLICATIONS: {
      return {
        ...state,
        triggerRestoreApplications: action.value
      };
    }
    case actions.SET_SELECTED_GROUP_OPTION: {
      return {
        ...state,
        selectedGroupOption: action.value
      };
    }
    case actions.SET_SELECTED_SORT_OPTION: {
      return {
        ...state,
        selectedSortOption: action.value
      };
    }
    case actions.SET_FILTERS: {
      return {
        ...state,
        filters: action.value
      };
    }
    case actions.SET_SYNONYMS: {
      return {
        ...state,
        synonymsList: action.value
      };
    }
    case actions.SET_SHOW_UNIQUE_RESULTS: {
      return {
        ...state,
        showUniqueResults: action.value
      };
    }
    case actions.SET_EXPLORE_PAGE_RESULTS_COUNT: {
      return {
        ...state,
        exploreResultLength: action.value
      };
    }
    case actions.SET_EXPLORE_PAGE_MAPPER: {
      return {
        ...state,
        explorePageMapper: action.value
      };
    }
    case actions.SET_COMPARISON_APPLICATIONS: {
      return {
        ...state,
        comparisonApplications: action.value
      };
    }
    case actions.SET_TRIALS_DATA: {
      return {
        ...state,
        trialsData: action.value
      };
    }
    case actions.SET_TRIALS_LOADING: {
      return {
        ...state,
        trialLoading: action.value
      };
    }
    case actions.SET_TRIALS_ERROR: {
      return {
        ...state,
        trialError: action.value
      };
    }
    case actions.SET_CT_ADVERSE_EVENTS_LOADING: {
      return {
        ...state,
        ctAdverseEventLoading: action.value
      };
    }
    case actions.SET_CT_BUSINESS_INTELLIGENCE_LOADING: {
      return {
        ...state,
        ctBusinessIntelligenceLoading: action.value
      };
    }
    case actions.SET_CT_BUSINESS_INTELLIGENCE: {
      return {
        ...state,
        ctBussinessIntelligence: action.value
      };
    }
    case actions.SET_CT_ADVERSE_EVENTS: {
      return {
        ...state,
        ctAdverseEvent: action.value
      };
    }
    case actions.SET_SEARCH_IDS: {
      return {
        ...state,
        searchIds: action.value
      };
    }
    case actions.SET_CT_RESULTS: {
      return {
        ...state,
        ctResults: action.value
      };
    }
    case actions.SET_IS_CONTENT_LOADING: {
      return {
        ...state,
        isContentLoading: action.value
      };
    }
    case actions.SET_ORIGINAL_TEXT_ENABLED: {
      return {
        ...state,
        originalTextEnabled: action.value
      };
    }
    case actions.SET_IS_ARIA_OPENED: {
      return {
        ...state,
        isAriaOpened: action.value
      };
    }
    case actions.SET_ARIA_ENTITY_IDS: {
      return {
        ...state,
        ariaEntityIds: action.value
      };
    }
    case actions.SET_DOCUMENT_LOADER: {
      return {
        ...state,
        documentLoader: action.value
      };
    }
    case actions.SET_ARIA_FILTERS: {
      return {
        ...state,
        ariaFilters: action.value
      };
    }
    case actions.SET_ARIA_RESPONSE: {
      return {
        ...state,
        ariaResponse: action.value
      };
    }
    case actions.SET_ARIA_RESPONSE_LOADING: {
      return {
        ...state,
        ariaResponseLoading: action.value
      };
    }
    case actions.SET_SELECTED_ARIA_RESPONSE: {
      return {
        ...state,
        selectedAriaResponse: action.value
      };
    }
    case actions.SET_CACHED_ARIA_RESPONSE: {
      return {
        ...state,
        cachedAriaResponse: action.value
      };
    }
    case actions.SET_ARIA_RESPONSE_ANSWER_TEXT: {
      return {
        ...state,
        ariaResponseAnswerText: action.value
      };
    }
    case actions.SET_EXPLORE_RESULTS_OPEN: {
      return {
        ...state,
        exploreResultsOpen: action.value
      };
    }
    case actions.SET_COLLATED_SUMMARY_SELECTION: {
      return {
        ...state,
        collatedSummarySelection: action.value
      };
    }
    case actions.SET_COLLATED_SUMMARY_APPLICATIONS: {
      return {
        ...state,
        collatedSummaryApplications: action.value
      };
    }
    case actions.SET_COLLATED_SUMMARY_SELECTED_APPLICATIONS: {
      return {
        ...state,
        collatedSummarySelectedApplications: action.value
      };
    }
    case actions.SET_COLLATED_SUMMARY_SELECTED_CATEGORIES: {
      return {
        ...state,
        collatedSummarySelectedCategories: action.value
      };
    }
    case actions.SET_CT_STATS: {
      return {
        ...state,
        ctStats: action.value
      };
    }
    case actions.SET_CT_FILTERS: {
      return {
        ...state,
        ctFilters: action.value
      };
    }
    case actions.SET_CT_PAGINATION: {
      return {
        ...state,
        ctPagination: action.value
      };
    }
    case actions.SET_CT_IS_LOADING: {
      return {
        ...state,
        ctIsLoading: action.value
      };
    }
    case actions.SET_CT_DATA_GRID_FILTERS: {
      return {
        ...state,
        ctDataGridFilters: action.value
      };
    }
    case actions.SET_TOP_10_SUMMARY: {
      return {
        ...state,
        top10Summary: action.value
      };
    }
    case actions.SET_SHOW_TOP_10_SUMMARY: {
      return {
        ...state,
        showTop10Summary: action.value
      };
    }
    case actions.SET_TOP_10_SUMMARY_LOADING: {
      return {
        ...state,
        top10SummaryLoading: action.value
      };
    }
    case actions.SET_RIA_RESPONSE_TITLE: {
      return {
        ...state,
        riaResponseTitle: action.value
      };
    }
    case actions.SET_RESULT_IDS_IN_VIEW: {
      return {
        ...state,
        resultsIdsInView: action.value
      };
    }
    case actions.SET_COLLATED_SUMMARY_DOWNLOAD_TYPE: {
      return {
        ...state,
        collatedSummaryDownloadType: action.value
      };
    }
    case actions.SET_LABEL_COMPARISON_SELECTION_METHOD: {
      return {
        ...state,
        labelComparisonSelectionMethod: action.value
      };
    }
    case actions.SET_CT_SORT_BY: {
      return {
        ...state,
        ctSortBy: action.value
      };
    }
    case actions.SET_CT_EXPORT:
      return {
        ...state,
        ctExport: action.value
      };
    case actions.SET_IS_SOURCE_CHANGED_WITH_FILTERS: {
      return {
        ...state,
        isSourceChangedWithFilters: action.value
      };
    }
    case actions.SET_IS_NEW_ADVANCE_SEARCH: {
      return {
        ...state,
        isNewAdvanceSearch: action.value
      };
    }
    case actions.SET_SEARCH_SUGGESTIONS: {
      return {
        ...state,
        searchSuggestions: action.value
      };
    }
    case actions.SET_ADVANCE_SEARCH_ALERT_MSG: {
      return {
        ...state,
        advanceSearchAlertMsg: action.value
      };
    }
    case actions.SET_IS_SEARCH_BY_SEARCH_ID: {
      return {
        ...state,
        isSearchBySearchId: action.value
      };
    }
    case actions.SET_IS_NEW_SEARCH_TERM: {
      return {
        ...state,
        isNewSearchTerm: action.value
      };
    }
    case actions.SET_SCROLL_RESULT_ID: {
      return {
        ...state,
        scrollResultId: action.value
      };
    }
    case actions.SET_APPLICATION_EXPORT: {
      return {
        ...state,
        applicationExport: action.value
      };
    }
    case actions.SET_DATA_GRID_META_DATA: {
      return {
        ...state,
        dataGridMetaData: action.value
      };
    }
    case actions.SET_RELEVANT_TERMS: {
      return {
        ...state,
        relevantTerms: { ...action.value }
      };
    }
    case actions.SET_DEFAULT_SOURCES: {
      return {
        ...state,
        defaultSources: action.value
      };
    }
    default: {
      return state;
    }
  }
};

export default reducers;
